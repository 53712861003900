import { render, staticRenderFns } from "./presale.vue?vue&type=template&id=5a123760&scoped=true&"
import script from "./presale.vue?vue&type=script&lang=js&"
export * from "./presale.vue?vue&type=script&lang=js&"
import style0 from "./presale.vue?vue&type=style&index=0&id=5a123760&lang=less&scoped=true&"
import style1 from "./presale.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a123760",
  null
  
)

export default component.exports