<template>
  <section class="m-presale m-scroll" ref="scroll" @scroll="handleScroll($event)" :style="{backgroundColor: bgColor}">
    <!-- 头部导航 -->
    <div class="header" :style="`background:rgba(255,255,255,${(this.offsetTop-100) / 100});`">
      <img @click="handleBack" src="@/assets/goods/icon_back_2@2x.png" alt="back">
      <van-search v-model="searckKey" placeholder="请输入关键词" autofocus clearable
      @search="onSearch" shape="round" background="transparent"></van-search>
      <router-link to="/zh/cart" >
        <img src="@/assets/goods/cart.png" alt="img">
        <m-dot :number="cartNum" :animate="dotAnimate" ref="dot"></m-dot>
      </router-link>
    </div>
    <div class="header-bg">
      <img :src="banner" alt="">
    </div>

    <div class="hot-sale sale-box">
      <div class="sale-more">
        <img src="@/assets/presale/biaotibg@2x.png" alt="">
        <div class="more-link" @click="$router.push('/zh/subsale?type=hot&title=热门预售')">
          更多
          <img src="@/assets/presale/icon-fanhui@2x.png" alt="">
        </div>
      </div>
      <div class="more-color"></div>
      <div class="more-color"></div>
      <goods-columns @cartClick="handleCart" :data="hotList" :columns="2"></goods-columns>
    </div>
    <!-- <img src="@/assets/presale/bg-color.png" alt="" class="bg-color"> -->
    <div class="time-sale sale-box">
      <div class="sale-more">
        <img src="@/assets/presale/time.png" alt="">
        <div class="more-link" @click="$router.push('/zh/subsale?type=limit&title=限量好货')">
          更多
          <img src="@/assets/presale/icon-fanhui@2x.png" alt="">
        </div>
      </div>
      <div class="more-color"></div>
      <div class="more-color"></div>
      <goods-columns @cartClick="handleCart" :data="limitList" :columns="2" :loading="loading"></goods-columns>
      <p class="search-no" v-if='limitList.length <= 0'>暂无商品</p> 
    </div>
    <!-- <img src="@/assets/presale/bg-color.png" alt="" class="bg-color"> -->

    <div class="goods-main">
      <!-- 滚动切换 -->
      <van-tabs :offset-top="tabTop" title-active-color="#ED2A24" title-inactive-color="#000000" v-model="activeSectionId"
      style="position:sticky;top:44px;z-index:99">
        <van-tab :title="item.title" v-for="(item, index) in classifyList" :key="index" :name="item.id">
          <!-- <index-cell :title="item.title" value="更多"  to="/" class="title"></index-cell> -->
        </van-tab>
      </van-tabs>
      <goods-columns v-if="classifyList[activeIndex]" @cartClick="handleCart" :data="goodsList" :columns="classifyList[activeIndex].row_pro_num"
      :loading="loading" />
      <p class="search-no" v-if='noMore'>暂无更多商品</p>  
      <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>
    </div>    

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MDot         from '@/components/zh/m-dot.vue'
import MSku         from '@/components/zh/m-sku.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'

import { getGiftColor } from '@/api/zh/mooby.js'
import { getHotPreSale, getLimitPreSale, getPreSaleClassify, getPreSaleClassifyGoods, getPreSaleBanner } from '@/api/zh/presale.js'

import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  components:{ MDot, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      offsetTop : 0,
      tab       : 0,          // tabs选中的面板
      recomnd   : 4,
      loading   : false,
      skuModal  : false,
      start     : false,
      start_dom : null,
      tabTop    : 44,
      dotAnimate: false,
      cartNum   : 0,
      scrollTop : false,
      hotList   : [],
      limitList : [],
      activeSectionId: '',
      activeIndex: 0,
      noMore: false,
      islock: false,
      goodsList: [],
      page: 1,
      goodsId: '',
      classifyList: [],
      banner: '',
      bgColor: '',
      searckKey: ''
    }
  },

  mounted(){
    let clientWidth = document.body.clientWidth
    this.tabTop = 44/375*clientWidth

    this.getHotPreSaleHandle()
    this.getLimitPreSaleHandle()
    this.getPreSaleClassifyHandle()
    this.getPreSaleBannerHandle()
    this.getPreColorHandle()
    localStorage.setItem('_back', '/')
  },

  methods:{
    // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop

      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock && !this.noMore){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getPreSaleClassifyGoodsHandle()
        }
      }
    },
    handleBack(){
      this.$router.go(-1)
    },
    onSearch() {
      // 前往搜索页面
      localStorage.setItem('back_path', this.$route.fullPath)
      this.$router.push(`/zh/search?s=presale&key=${this.searckKey}`)
    },
    // 切换 tab
    handleTab(tab){
      let left = 6.56667 + tab  * 21.66667
      this.$refs.tabActive.style.left = left + 'vw'
      this.tab = tab
    },
    // 点击筛选 tab
    handleFilter(){
      this.filter = true
    },
    // 点击月份筛选
    handleMonth(month){
      this.month = month
      let timer = setTimeout( () => {
        this.filter = false
        clearTimeout(timer)
      }, 300)
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++
        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)
        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getHotPreSaleHandle() {
      // 获取热门预售商品
      getHotPreSale(2,{is_page:1,per_page:20}).then(res => {
        if(res) {
          this.hotList = res.data.data.slice(0, 10)
        }
      })
    },
    getLimitPreSaleHandle() {
      // 获取预售商品
      getLimitPreSale(3,{is_page:1,per_page:20}).then(res => {
        if(res) {
          this.limitList = res.data.data.slice(0, 10)
        }
      })
    },
    getPreSaleClassifyHandle() {
      // 获取预售分类
      getPreSaleClassify().then(res => {
        if(res) {
          if (this.$store.state.isMiniProgram) {
              this.classifyList = res.data.filter(item => {
                    return  item.id != 34 && item.id != 35 && item.id != 36 && item.id != 37 && item.id != 38 && item.id != 45 && item.id != 46 && item.id != 49 && item.id != 50 && item.id != 53 && item.id != 55
                });
          }else{
              this.classifyList = res.data
          }
          if(res.data.length > 0) {
            this.activeSectionId = res.data[0].id
          }
        }
      })
    },
    getPreSaleClassifyGoodsHandle() {
      // 获取预售分类商品
      this.islock = true
      this.loading  = true
      getPreSaleClassifyGoods(this.activeSectionId,{page: this.page}).then(res => {
        this.loading  = false
        this.islock   = false
        if(res) {
          this.goodsList = this.goodsList.concat(res.data.data)
        }else{
          this.noMore = true
        }
      })
    },
    getPreSaleBannerHandle() {
      // 获取预售banner
      getPreSaleBanner(this.$route.query.id).then(res => {
        if(res && res.data.length >= 1) {
          this.banner = res.data[0].pic_url
        }
      })
    },
    getPreColorHandle() {
      // 获取新品预售Color
      getGiftColor(this.$route.query.id).then(res => {
        if(res) {
          this.bgColor = res.color
        }
      })
    }
  },
  watch: {
    activeSectionId(val) {
      if(val) {
        this.classifyList.forEach((el, index) => {
          if(el.id == val) {
            this.activeIndex = index
          }
        })
        this.goodsList = []
        this.page = 1
        this.islock   = false
        this.noMore = false
        this.getPreSaleClassifyGoodsHandle()
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './presale.less';
</style>
<style lang="less">
.m-presale{
  .van-tabs{margin-top:-40px;
    >div:nth-of-type(1){
      margin-left:16px;
      border-radius: 6px 0 0 6px;
      overflow: hidden;
    }
  }
  .van-tabs__content{margin-top:20px}
  .search-no {line-height:80px;text-align:center;color:#888;font-size:14px}
}
</style>